import Hero from "../General/Hero"

const Fees = () => {
  const headingClass = "text-center bg-my-olive text-white border border-my-olive text-xl uppercase p-3 font-bold"
  const subHeadingClass = "text-left bg-my-olive text-white text-md capitalize p-2 border border-my-grey border-l-0 font-normal"
  const cellClass = "p-2 border border-my-grey border-x-0 border-b-0 border-r text-left"
  return <div>
    <Hero title="Our Fees" />
    <div className="max-w-screen-md mx-auto p-2">
      <p className="hidden">
        I feel like we need some form of blurb here to at least fill some space or even to tell people about how awesome we are.
      </p>
      <div className="grid grid-cols-4 border border-my-grey border-r-0">
        <div className={`${headingClass} col-span-4`}>
          General Fees/Information
        </div>
        <div className={`${cellClass} col-span-2`}>
          Account Setup
        </div>
        <div className={`${cellClass} col-span-2`}>
          Free
        </div>
        <div className={`${cellClass} col-span-2`}>
          Monthly subscription
        </div>
        <div className={`${cellClass} col-span-2`}>
          Free
        </div>
        <div className={`${cellClass} col-span-2`}>
          Create jars
        </div>
        <div className={`${cellClass} col-span-2`}>
          Free
        </div>
        <div className={`${cellClass} col-span-2`}>
          Withdraw funds
        </div>
        <div className={`${cellClass} col-span-2`}>
          Free
        </div>
        <div className={`${cellClass} col-span-2`}>
          Time for withdrawn funds to arrive in your bank account
        </div>
        <div className={`${cellClass} col-span-2`}>
          Usually instant, but please allow up to 48hours for busy periods
        </div>
      </div>

      <div className="grid grid-cols-3 border border-my-grey border-r-0 border-t-0">
        <div className={`${headingClass} col-span-3`}>
          Service/Convenience Fees
        </div>
        <div className={`${subHeadingClass}`}>
          Payment Amount
        </div>
        <div className={`${subHeadingClass}`}>
          Service Fee
        </div>
        <div className={`${subHeadingClass} break-normal`}>
          Convenience Fee
        </div>

        <div className={`${cellClass} border-t-0 col-span-1`}>
          Less than £2
        </div>
        <div className={`${cellClass} border-t-0 col-span-1`}>
          -
        </div>
        <div className={`${cellClass} border-t-0 col-span-1`}>
          £0.20
        </div>

        <div className={`${cellClass} col-span-1`}>
          From £2 to £3000
        </div>
        <div className={`${cellClass} col-span-1`}>
          1%
        </div>
        <div className={`${cellClass} col-span-1`}>
          £0.20
        </div>

      </div>

      {/* <table className="text-left w-full table-fixed">
        <thead>
          <tr>
            <th colSpan={4} scope="col" className={`${headingClass}`}>
              General Fees/Information
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`${cellClass} border-t-0 w-1/2`} colSpan={2}>
              Account setup
            </td>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Monthly subscription
            </td>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Create jars
            </td>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Withdraw funds
            </td>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Time for withdrawn funds to arrive in your bank account
            </td>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Usually instant, but please allow up to 48hours for busy periods
            </td>
          </tr>
          <tr>
            <th colSpan={4} scope="col" className={`${headingClass} border-b-my-grey`}>
              Service/Convenience Fees
            </th>
          </tr>
          <tr>
            <td colSpan={2} className={`${subHeadingClass}`}>
              Payment Amount
            </td>
            <td className={`${subHeadingClass}`}>
              Service Fee
            </td>
            <td className={`${subHeadingClass} break-normal`}>
              Convenience Fee
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Payments less than £2
            </td>
            <td className={`${cellClass} border-t-0`}>
              -
            </td>
            <td className={`${cellClass} border-t-0`}>
              £0.20
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`} colSpan={2}>
              Payments from £2 to £3000
            </td>
            <td className={`${cellClass}`}>
              1%
            </td>
            <td className={`${cellClass}`}>
              £0.20
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  </div>
}

export default Fees