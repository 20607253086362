const FeeDisplay = ({feeIncluded, totalAmount, giftAmount, feeAmount}: {feeIncluded: boolean, totalAmount: number, giftAmount: number, feeAmount: number}) => {
  return <ul>
    <li>You Get: &pound;{(giftAmount).toFixed(2)}</li>
    <li>
      {
        (feeIncluded === true && totalAmount < 2)
        || (feeIncluded === false && giftAmount < 2)
      ?
        `Convenience Fee: £${feeAmount.toFixed(2)}`
      :
        `Fees [1% Service + 20p Convenience]: £${feeAmount.toFixed(2)}`
      }
    </li>
    <li><b>Bill Amount: &pound;{totalAmount.toFixed(2)}</b></li>
  </ul>
}

export default FeeDisplay